import { Controller } from '@hotwired/stimulus'

// Connects to data-controller="order-status"
export default class extends Controller {
  static values = { currentStatus: String }

  async connect() {
    // Check order status as a fallback to Turbo broadcasts from the backend
    await this.checkOrderStatus()
  }

  async checkOrderStatus() {
    if (this.currentStatusValue === 'pending') {
      // Wait 5 seconds
      await new Promise((resolve) => setTimeout(resolve, 5000))

      // Revisit the current page using Turbo
      // eslint-disable-next-line no-undef
      Turbo.visit(window.location.href, { action: 'replace' })
    }
  }
}
