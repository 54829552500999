// Turbo
import '@hotwired/turbo-rails'

// Stimulus Controllers
import '@/controllers/stores_v2'

// Custom Turbo stream actions
import { setupRedirect } from '@/turbo_stream_actions/redirect'
setupRedirect()

// SVG
import { SetupSvg } from '@/shared/utils'

document.addEventListener('turbo:load', function () {
  SetupSvg()
})

// Stripe
import '@stripe/stripe-js'
