import { Controller } from '@hotwired/stimulus'
import { EnableButtonLoading, DisableButtonLoading } from '../shared/utils/loading_button.js'

// Connects to data-controller="order"
export default class extends Controller {
  async downloadPic(event) {
    event.preventDefault()

    const button = event.currentTarget
    const downloadUrl = button.dataset.downloadUrl
    const filename = button.dataset.filename

    EnableButtonLoading(button)

    try {
      const response = await fetch(downloadUrl)
      const blob = await response.blob()

      // Create a temporary link to the blob
      const objectUrl = URL.createObjectURL(blob)
      const a = document.createElement('a')
      a.href = objectUrl

      // Trigger download
      a.download = filename
      document.body.appendChild(a)
      a.click()

      // Clean up
      DisableButtonLoading(button)
      document.body.removeChild(a)
      URL.revokeObjectURL(objectUrl)
    } catch (err) {
      DisableButtonLoading(button)
    }
  }
}
