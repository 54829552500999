import BasicModalController from '../../shared/modals/basic_modal_controller'

// Connects to data-controller="basket-modal"
export default class extends BasicModalController {
  static values = {
    isDismissible: Boolean,
    dismissPath: String
  }

  static targets = ['viewBasketButton']

  viewBasketButtonTargetConnected() {
    if (this.viewBasketButtonTarget.dataset.updated === 'true') {
      setTimeout(() => {
        this.viewBasketButtonTarget.dataset.updated = 'false'
      }, '1000')
    }
  }

  addingToBasket(event) {
    event.target.setAttribute('disabled', '')
    event.target.innerHTML = 'Adding to basket'
  }

  dismiss(event) {
    // Override dismiss() to always remove the basket_open URL param
    super.dismiss(event)
    this.removeBasketUrlParam()
  }

  removeBasketUrlParam() {
    const url = new URL(window.location.href)

    // Check if the 'basket_open' param exists
    if (url.searchParams.has('basket_open')) {
      url.searchParams.delete('basket_open')

      // Update the URL without refreshing
      window.history.replaceState({}, document.title, url.toString())
    }
  }
}
