import { Controller } from '@hotwired/stimulus'

const STRIPE_JS_BETA_VERSION = 'custom_checkout_beta_6'
import { loadStripe } from '@stripe/stripe-js'

import { EnableButtonLoading, DisableButtonLoading } from '../shared/utils/loading_button.js'

// Connects to data-controller="checkout"
export default class extends Controller {
  static targets = ['formElement', 'emailInput', 'paymentElement', 'errorElement', 'submitButton']
  static values = { loading: String, paymentProcessing: String }

  async connect() {
    this.stripe = null
    this.stripeCheckout = null
    this.stripeCheckoutSessionId = null

    this.lineItems = null
    this.currency = null
    this.totalAmount = null

    this.clientId = null
    this.formSubmitted = false

    await this.setupStripeForm()
  }

  async setupStripeForm() {
    await this.connectToStripe()

    const fetchClientSecret = async () => {
      const response = await fetch(`${window.location.pathname}/create_checkout_session`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          'X-CSRF-Token': document.querySelector('meta[name="csrf-token"]').getAttribute('content')
        }
      })
      const { checkoutSessionId, clientSecret, clientId } = await response.json()

      this.stripeCheckoutSessionId = checkoutSessionId
      this.clientId = clientId

      return clientSecret
    }

    const appearance = {
      // See all possible variables - https://docs.stripe.com/elements/appearance-api
      theme: 'stripe',
      variables: {
        colorPrimary: '#000',
        colorText: '#30313D',
        colorDanger: '#BD2C00',
        fontFamily: 'Rubik, sans-serif'
      },
      rules: {
        '.Input': {
          boxShadow: 'none',
          borderRadius: '4px',
          borderColor: '#E8E8E8'
        },
        '.AccordionItem': {
          borderLeft: 'none',
          borderRight: 'none',
          borderColor: '#E8E8E8',
          boxShadow: 'none',
          borderRadius: '0'
        }
      }
    }

    const fonts = [{ cssSrc: 'https://fonts.googleapis.com/css2?family=Rubik' }]

    const paymentElementOptions = {
      layout: 'accordion',
      fields: {
        billingDetails: 'auto'
      }
    }

    // Initialize Stripe checkout using client secret & options
    this.stripeCheckout = await this.stripe.initCheckout({
      fetchClientSecret,
      elementsOptions: {
        appearance: appearance,
        loader: 'auto',
        fonts: fonts
      }
    })

    // Set line items & currency (required by Stripe)
    this.lineItems = JSON.stringify(this.stripeCheckout.lineItems, null, 2)
    this.currency = this.stripeCheckout.session().currency
    this.totalAmount = this.stripeCheckout.session().total.total.amount

    // Setup payment element
    const paymentElement = this.stripeCheckout.createPaymentElement(paymentElementOptions)
    paymentElement.mount(this.paymentElementTarget)

    paymentElement.on('ready', () => {
      this.loadingValue = 'false'
    })

    paymentElement.on(
      'change',
      function (event) {
        // When the entire form has been successfully filled out
        // clear any previous errors
        if (event.complete) {
          this.clearErrorMessage()
        }
      }.bind(this)
    )
  }

  async connectToStripe() {
    const stripeKey = document.querySelector('meta[name="stripe-key"]').getAttribute('content')
    this.stripe = await loadStripe(stripeKey, { betas: [STRIPE_JS_BETA_VERSION] })
  }

  async submit(event) {
    event.preventDefault()

    // Prevent double form submission
    if (this.formSubmitted) {
      return
    }

    // Reset a couple of things
    this.clearErrorMessage()
    this.emailInputTarget.classList.remove('invalid')

    // Validate email input
    if (!this.isEmailValid()) {
      this.emailInvalid()
      return
    }

    // Start processing
    this.enableProcessing()

    // Create draft order
    const draftOrderCreated = await this.createDraftOrder()
    if (!draftOrderCreated) {
      this.disableProcessing()
      return
    }

    // Submit Stripe payment
    const { error: stripeError } = await this.stripeCheckout.confirm({ email: this.emailInputTarget.value })

    if (stripeError) {
      this.displayErrorMessage(stripeError.message)
      this.disableProcessing()
    }
  }

  enableProcessing() {
    this.formSubmitted = true
    this.paymentProcessingValue = 'true'
    EnableButtonLoading(this.submitButtonTarget)
  }

  disableProcessing() {
    this.formSubmitted = false
    this.paymentProcessingValue = 'false'
    DisableButtonLoading(this.submitButtonTarget)
  }

  emailInvalid() {
    this.emailInputTarget.classList.add('invalid')
    this.emailInputTarget.focus()
    this.displayErrorMessage('Please enter a valid email address.')
  }

  isEmailValid() {
    const re = /\S+@\S+\.\S+/
    return re.test(this.emailInputTarget.value)
  }

  displayErrorMessage(message) {
    this.errorElementTarget.innerHTML = message
    this.errorElementTarget.classList.remove('ia-hidden')
  }

  clearErrorMessage() {
    this.errorElementTarget.innerHTML = ''
    this.errorElementTarget.classList.add('ia-hidden')
  }

  async createDraftOrder() {
    const response = await fetch(`${window.location.pathname}/create_draft_order`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        'X-CSRF-Token': document.querySelector('meta[name="csrf-token"]').getAttribute('content')
      },
      body: JSON.stringify({
        email: this.emailInputTarget.value,
        client_id: this.clientId,
        checkout_session_id: this.stripeCheckoutSessionId
      })
    })

    const { status, error } = await response.json()

    if (status === 'error') {
      if (error === 'invalid_email') {
        this.emailInvalid()
      } else {
        this.displayErrorMessage('There was a server error on our end, no payment has been taken.')
      }
      return false
    }

    return true
  }
}
